<template>
    <div class="container mt-4 mb-3 terms">
        <div class="row">
            <div class="col-12">
                <h1 class="pb-3">
                    Política de Privacidade e Política de Cookies
                </h1>
                 
                <p>
                    Esta Política de Privacidade e de Cookies regula o tratamento dos dados pessoais dos utilizadores
                    (doravante <strong>“Utilizador”</strong> ou <strong>“Utilizadores”</strong>), recolhidos no âmbito
                    da utilização
                    do Website <a href="https://www.k2go.pt/" target="_blank">https://www.k2go.pt/</a> (doravante
                    “Site”), pela K2GO Rent a Car (doravante <strong>K2GO</strong>), enquanto
                    entidade responsável pelo tratamento.
                </p>
                 
                <p>
                    A <strong>K2GO</strong> procura assegurar o respeito pela privacidade dos seus clientes e o
                    cumprimento das obrigações
                    legais aplicáveis nesta matéria. Assim, a <strong>K2GO</strong> garante aos Utilizadores
                    deste Site o respeito pela
                    sua
                    privacidade, adotando as medidas necessárias para proteger os seus dados pessoais.
                </p>
                 
                <p>
                    A disponibilização dos dados pessoais implica o conhecimento e a aceitação das condições constantes
                    desta Política de Privacidade e de Cookies.
                </p>
                 
                <p>
                    A presente Política de Privacidade e de Cookies é complementada pelos Termos e Condições de
                    Utilização,
                    disponíveis em <a href="https://www.k2go.pt/pt/condicoes-gerais" target="_blank">https://www.k2go.pt/</a>
                </p>  
                <p>
                    Nos pontos abaixo identificados, poderá encontrar informação detalhada relativa à Política de
                    Privacidade e de Cookies da <strong>K2GO</strong>:
                </p>
                 

                <h2>1. Recolha e Tratamento de Dados Pessoais</h2>
                <p>
                    O acesso e utilização do Site não implicam a disponibilização de dados pessoais pelo Utilizador.
                </p>
                <p>
                    Contudo, para poder aceder e utilizar determinadas funcionalidades do Site, nomeadamente para:
                </p>
                <p>
                    - realizar reservas de veículos;
                </p>
                <p>
                    - entrar em contacto connosco através do nosso chat ou mediante preenchimento de formulário de
                    contacto;
                </p>
                <p>
                    - submeter candidaturas espontâneas;
                </p>
                <p>
                    O Utilizador deve disponibilizar alguns dos seus dados pessoais.
                </p>
                <p>
                    A <strong>K2GO</strong> é responsável pela recolha e tratamento dos dados pessoais dos Utilizadores
                    do Site, nos
                    termos e
                    para os efeitos indicados na legislação de proteção de dados pessoais aplicável.
                </p>
                <p>
                    Tais dados serão tratados pela <strong>K2GO</strong> para realizar a reserva de veículos, gerir os
                    seus pedidos de
                    contactos e esclarecimentos, dar prosseguimento ao processo de recrutamento e. Os dados recolhidos
                    são
                    indispensáveis à prossecução das finalidades referidas, pelo que todos os dados requeridos são de
                    fornecimento obrigatório, sob pena de não ser possível dar seguimento à sua reserva, à prestação de
                    esclarecimentos e ao pedido de contacto ou à sua candidatura.
                </p>
                <p>
                    Mediante o consentimento do Utilizador, os respetivos dados poderão ainda ser tratados para as
                    finalidades de envio de comunicações promocionais e de marketing.
                </p>
                <h2> 2. Direitos do Utilizador</h2>
                <p>
                    Nos termos da legislação aplicável, é garantido ao Utilizador, a todo o tempo, o direito a obter
                    da <strong>K2GO</strong>, a confirmação acerca dos seus dados pessoais que são objeto de tratamento,
                    o acesso aos
                    dados
                    pessoais que lhe digam respeito, bem como a sua retificação, eliminação e limitação do seu
                    tratamento, a
                    portabilidade dos seus ou opor-se ao tratamento dos seus dados, caso estejam verificadas as
                    condições
                    legalmente previstas.
                </p>
                <p>
                    Os direitos do Utilizador à luz da legislação aplicável no âmbito da proteção de dados consistem
                    em:
                </p>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>Direitos do Titular</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Direito de Acesso</th>
                            <td>
                                Direito de obter a confirmação se os seus dados são objeto de tratamento, informação
                                sobre
                                quais dos
                                seus dados pessoais são objeto de tratamento, bem como obter cópia dos seus dados
                                pessoais
                                em fase
                                de
                                tratamento.
                                O direito de acesso não prejudica os direitos e liberdades de terceiros, incluindo o
                                segredo
                                comercial
                                ou a propriedade intelectual.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito de Retificação</th>
                            <td>
                                Direito de solicitar a retificação dos seus dados pessoais que se encontrem inexatos ou
                                solicitar
                                que os
                                dados pessoais incompletos sejam completados.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito ao Apagamento</th>
                            <td>
                                Direito de obter o apagamento dos seus dados pessoais.
                                Tal direito não se aplicará quando o tratamento dos dados for necessário para o
                                cumprimento
                                de
                                uma
                                obrigação legal a que a <strong>K2GO</strong> esteja sujeita.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito à Limitação do Tratamento</th>
                            <td>
                                Direito de solicitar a limitação do tratamento dos seus dados pessoais, requerendo a
                                suspensão
                                do
                                tratamento ou a limitação do âmbito do tratamento a certas categorias de dados ou
                                finalidades de
                                tratamento.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito à Portabilidade</th>

                            <td>
                                Direito de receber os dados que forneceu à <strong>K2GO</strong> em formato digital de
                                uso
                                corrente e de
                                leitura
                                automática ou solicitar a transmissão direta dos seus dados para outra entidade que
                                passe a
                                ser
                                a
                                nova
                                responsável pelos seus dados pessoais, se o tratamento dos seus dados tiver por base o
                                seu
                                consentimento
                                ou a execução de um contrato.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito à Oposição</th>

                            <td>
                                Direito de se opor a um tratamento de dados, como por exemplo no caso de tratamento de
                                dados
                                para
                                fins
                                do envio de comunicações de marketing com base nos interesses legítimos
                                da <strong>K2GO</strong>.
                            </td>
                        </tr>
                        <tr>
                            <th>Direito de Retirar o Consentimento</th>
                            <td>
                                Direito de retirar o consentimento para o tratamento dos dados sempre que o tratamento
                                seja
                                efetuado
                                com
                                base no consentimento, não invalidando, no entanto, o tratamento efetuado até essa data
                                com
                                base
                                no
                                consentimento previamente dado.
                            </td>
                        </tr>
                         
                        </tbody>
                    </table>
                </div>
                <p>
                    Para o exercício de qualquer um dos direitos acima referidos, o Utilizador deverá submeter um pedido
                    escrito para os seguintes contactos:
                </p>
                <p>
                    Telefone: <a href="tel:(+351) 918 925 882">(+351) 918 925 882</a>
                </p>
                <p>
                    E-mail: <a href="mailto:geral.k2go@gmail.com">geral.k2go@gmail.com</a>
                </p>
                <p>
                    Morada: Loteamento Industrial, Lote 12, Herdade da Galega - Ecoparque do
                    Relvão 2140-671 Carregueira
                </p>
                <p>
                    O disposto é aplicável, com as devidas adaptações, ao exercício de direitos, por parte do titular
                    das
                    responsabilidades parentais ou tutor, em nome e por conta de titulares dos dados que sejam menores
                    de 16
                    anos ou incapazes.
                </p>

                <h2>3. Conservação dos dados pessoais</h2>
                <p>
                    Os dados pessoais serão conservados de acordo com as respetivas finalidades e respeitando os prazos
                    legais aplicáveis. Assim, e sempre que não exista uma exigência legal específica, os dados serão
                    armazenados e conservados apenas pelo período adequado e na medida do necessário ao âmbito das
                    finalidades para as quais foram recolhidos, exceto se for exercido, dentro dos limites legais, o
                    direito
                    de oposição, direito ao apagamento ou caso retire o consentimento.
                </p>
                <p>
                    Em caso de litígio pendente, os dados poderão ser conservados até [seis meses após o trânsito em
                    julgado
                    da respetiva sentença].
                </p>


                <h2>4. Partilha de dados</h2>
                <p>
                    A <strong>K2GO</strong> poderá transmitir os seus dados a entidades subcontratadas para os efeitos
                    acima referidos,
                    nos
                    termos dos contratos celebrados com as mesmas.
                </p>
                <p>
                    Os dados do Utilizador poderão ainda ser comunicados a terceiros quando a transmissão seja efetuada
                    no
                    âmbito do cumprimento de uma obrigação legal, de uma deliberação da Comissão Nacional de Proteção de
                    Dados ou de outra entidade de controlo relevante, ou de uma ordem judicial, bem como quando a
                    comunicação seja levada a cabo para proteger interesses vitais dos Utilizadores ou qualquer outra
                    finalidade legítima da <strong>K2GO</strong> ou de terceiros prevista na lei.
                </p>

                <h2>5. Medidas de Segurança</h2>
                <p>
                    A <strong>K2GO</strong> desenvolve os seus melhores esforços para proteger os dados pessoais dos
                    Utilizadores contra
                    acessos não autorizados. Para o efeito, utiliza sistemas de segurança, regras e outros
                    procedimentos, de
                    modo a garantir a proteção dos dados pessoais, bem como para prevenir o acesso não autorizado aos
                    dados,
                    o uso impróprio, a sua divulgação, perda ou destruição.
                </p>
                <p>
                    É, no entanto, da responsabilidade dos Utilizadores, garantir e assegurar que os dispositivos e
                    equipamentos utilizados para aceder ao Site se encontram adequadamente protegidos
                    contra softwares nocivos, vírus informáticos e worms.
                </p>
                <p>
                    Adicionalmente, o Utilizador deverá estar ciente que, sem a adoção de medidas de segurança adequadas
                    (por exemplo, a configuração segura do programa de navegação, software antivírus
                    atualizado, software de
                    barreirade segurança e a não utilização de software de origem duvidosa), o risco de os dados
                    pessoais
                    e passwords serem acedidos por terceiros, sem autorização para tal, é agravado.
                </p>
                <p>
                    Note-se ainda que, sempre que a recolha de dados seja realizada em redes abertas, como a Internet,
                    os
                    seus dados poderão circular sem condições de segurança, existindo o risco de serem vistos e
                    utilizados
                    por terceiros não autorizados.
                </p>
                 
                <h2>6. Política de Cookies</h2>
                <p>
                    No momento de acesso ao Site, poderão ser instalados no computador ou dispositivo móvel do
                    Utilizador
                    pequenos ficheiros denominados cookies ou testemunhos de conexão. A utilização de cookies é uma
                    prática
                    standard na maioria dos websites. Para poder proporcionar aos seus Utilizadores uma melhor
                    experiência
                    de navegação e atualizar o Site com funções relevantes, a <strong>K2GO</strong> utiliza cookies.
                </p>
                <p>
                    <strong>O que são cookies?</strong>
                </p>
                <p>
                    A construção dos websites modernos depende da implementação de um conjunto de funções mínimas que
                    permitam corresponder às expetativas de navegação dos Utilizadores. Entre outras, é expectável que
                    durante um certo período de tempo, os websites mantenham as ações e preferências dos Utilizadores,
                    nomeadamente o seu username (nome de Utilizador), língua em que pretende navegar no Site ou outras
                    definições relevantes relacionadas com a interface dos websites. Os cookies são pequenos ficheiros
                    de
                    informação que são armazenados no dispositivo que utiliza para aceder à internet através do
                    seu browser e permitem a implementação das funcionalidades mencionadas.
                </p>
                <p>
                    <strong>Que tipo de cookies existem?</strong>
                </p>
                <p>
                    Existem diversos tipos de cookies.
                </p>
                <p>
                    Considerando o período de vida útil dos cookies, estes podem ser:
                </p>
                <ol type="a">
                    <li>
                        <strong>Cookies de sessão</strong> – são cookies de carácter provisório, sendo eliminados do
                        arquivo
                        de cookies aquando do fecho do browser ou aplicação utilizada para aceder ao website. Através
                        deste
                        tipo
                        de cookies é possível analisar padrões de tráfego na web, permitindo-nos identificar problemas e
                        fornecer uma melhor experiência de navegação.
                    </li>
                    <li>
                        <strong>Cookies permanentes</strong> – divergem dos cookies de sessão pois não são eliminados no
                        fecho
                        do browser ou
                        aplicação, permanecendo guardados nos dispositivos do Utilizador. São utilizados sempre que é
                        feita
                        uma
                        nova visita ao Site, permitindo-nos, entre outras funções, personalizar a experiência de
                        navegação
                        de
                        acordo com os interesses do Utilizador e prestar um serviço mais individualizado.
                    </li>
                </ol>
                <p>
                    Considerando, por sua vez, o domínio a que pertencem, os cookies podem ser:
                </p>
                <ol type="a">
                    <li>
                        <strong>Cookies próprios</strong> – são cookies enviados para o dispositivo do Utilizador
                        através de equipamentos
                        ou
                        domínios geridos pela <strong>K2GO</strong> e a partir do qual se presta o serviço solicitado
                        pelo Utilizador.
                    </li>
                    <li>
                        <strong>Cookies de terceiros</strong>  – são cookies enviados para o dispositivo do Utilizador a
                        partir de um
                        equipamento ou domínio gerido por uma entidade terceira sob a qual a <strong>K2GO</strong> não
                        tem controlo.
                        Nestes
                        casos
                        é esta entidade terceira que trata os dados recolhidos através dos cookies.
                    </li>
                     
                </ol>

                <p>
                    <strong>Que Cookies utilizamos e para que fins?</strong>
                </p>
                <p>
                    Os Cookies de seguida identificados são utilizados neste Site:
                </p>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th> Tipo de Cookies</th>
                            <th>Nome</th>
                            <th>Finalidade</th>
                            <th>Duração</th>
                            <th>Próprios/de terceiros</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>> Permanente</td>
                            <td>> _ga</td>
                            <td>> Usado para distinguir utilizadores.</td>
                            <td>> 2 anos</td>
                            <td>> de terceiros</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p>
                    <strong>Como bloquear/ desativar cookies?</strong>
                </p>
                <p>
                    Todos os browsers permitem ao respetivo Utilizador aceitar, recusar ou apagar cookies, nomeadamente
                    através da seleção das definições apropriadas no respetivo navegador.
                </p>
                <p>
                    Tenha em atenção, no entanto, que a desativação dos cookies pode afetar, parcial ou totalmente, a
                    sua
                    experiência de navegação no Site.
                </p>
                <p>
                    Pode configurar os cookies no menu "opções" ou "preferências" do seu browser. Abaixo, indicamos as
                    várias formas de desativação dos cookies, dependendo do seu browser.
                </p>
                <p>
                    Para saber mais sobre cookies, visite www.allaboutcookies.org, onde poderá encontrar informações
                    sobre
                    como gerir as suas configurações para os vários fornecedores de navegadores.
                </p>


                <h2>7. Links para Sites de Terceiros</h2>
                <p>
                    Os links constantes deste Site podem conduzir a outros sites. A <strong>K2GO</strong> não se
                    responsabiliza, aprova
                    ou
                    por qualquer forma apoia ou subscreve o conteúdo desses sites, nem dos sites com eles ligados ou
                    neles
                    referidos.
                </p>
                <p>
                    A <strong>K2GO</strong> não se responsabiliza por danos resultantes de vírus ou programas
                    informáticos nocivos que
                    possam
                    infetar o computador ou a rede do Utilizador, ou outros bens, em virtude do acesso a sites operados
                    por
                    terceiros.
                </p>
                <p>
                    A presente declaração de privacidade é aplicável unicamente à informação recolhida
                    no Site da <strong>K2GO</strong>.
                </p>
                <h2>8. Reclamações</h2>
                <p>
                    Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, o Utilizador tem direito a
                    apresentar uma reclamação junto da Comissão Nacional de Proteção de Dados ou de outra autoridade de
                    controlo competente nos termos da lei, caso entenda que o tratamento dos seus dados pela <strong>K2GO</strong> viola
                    o
                    regime legal em vigor a cada momento.
                </p>


                <h2>9. Questões e sugestões</h2>
                <p>
                    O Utilizador poderá contactar a <strong>K2GO</strong> sobre todas as questões relacionadas com o
                    tratamento dos seus
                    dados pessoais e com o exercício dos direitos que lhe são conferidos pela legislação aplicável e, em
                    especial, referidos na presente Política, através dos seguintes contactos
                </p>
                <p>
                    Telefone: <a href="tel:(+351) 918 925 882">(+351) 918 925 882</a>
                </p>

                <p>
                    E-mail: <a href="mailto:geral.k2go@gmail.com">geral.k2go@gmail.com</a>
                </p>

                <p>
                    Morada: Loteamento Industrial, Lote 12, Herdade da Galega - Ecoparque do
                    Relvão 2140-671 Carregueira
                </p>


                <h2>10. Alterações à Política de Privacidade e Política de Cookies</h2>
                <p>
                    A k2GO reserva-se o direito de, a todo o momento, alterar os presentes termos de tratamento de dados
                    pessoais. Essas alterações serão devidamente publicitadas no Site.
                </p>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name       : 'PrivacyPage',
        components : {},
        data       : function () {
            return {};
        }
    };
</script>
